<script setup lang="ts">
import { useSwiper } from 'swiper/vue'
import SwiperType from 'swiper'

const props = withDefaults(
	defineProps<{
		swiper?: SwiperType
		type?: 'dotted' | 'numbered'
	}>(),
	{
		type: 'dotted',
	},
)

const s = computed(() => {
	return props.swiper
		? props.swiper
		: useSwiper()
		? useSwiper().value
		: undefined
})
</script>

<template>
	<div
		class="swiper__pagination"
		:class="useSwiper() && `swiper__pagination--inside`"
		v-if="s && s.slides"
	>
		<MiscCarouselPagination
			:type="type"
			:current-index="s.realIndex"
			:amount="s.slides.length ? s.slides.length : 0"
			@pressed-next="s.slideNext()"
			@pressed-prev="s.slidePrev()"
		/>
	</div>
</template>

<style>
.swiper__pagination {
	@apply flex justify-center;

	&--inside {
		@apply md:absolute left-1/2 md:-translate-x-1/2 -bottom-24 my-6;
	}
}
</style>
